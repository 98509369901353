.header {
  // position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0 20px;
  width: 100%;
  background-color: var(--main-color1);

  @media screen and (min-width: 1000px) {
    position: relative;
    height: 70px;
    padding: 0 20px 0 43px;
  }
}

.logo {
  width: 150px;
  height: 52px;
  background: url(../../assets/auth/rixap_logo.png) center no-repeat;
  background-size: 150px 52px;
}

.attr_desk {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  // align-items: center;
  gap: 29px;

  @media screen and (max-width: 1000px) {
    display: none;
  }
}

.attr_mobile {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;

  @media screen and (min-width: 1000px) {
    display: none;
  }
}

.notification {
  display: block;
  width: 41px;
  height: 41px;
  background: url(../../assets/header/notification.svg) center no-repeat;
  transition: all 0.3s ease;

  &_active {
    background: url(../../assets/header/notification_outline.svg) center no-repeat;
  }
}

.heading {
  display: flex;
  gap: 21px;
  align-items: center;
}
.arrow {
  width: 34px;
  height: 34px;
  background: url(../../assets/header/left_arrow.svg) center no-repeat;
  cursor: pointer;
}
.title {
  color: #fff;
  font-size: 20px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 24px;
  max-width: 300px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subtitle {
  color: #fff;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
}

.link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
}

.text {
  color: #fff;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.733px;
}

.profile {
  width: 100%;
  max-width: 42px;
  height: 100%;
  max-height: 42px;
  border-radius: 50%;
}

.exit {
  width: 42px;
  height: 42px;
  background: url(../../assets/header/desktop/header_exit_desktop.svg) center no-repeat;
  display: block;
  cursor: pointer;
}

.newTask {
  width: 34px;
  height: 34px;
  background: url(../../assets/header/newtask.svg) center no-repeat;
  display: block;
  cursor: pointer;
}

.edit {
  padding: 0 10px;
  width: 26px;
  height: 24px;
  background: url(../../assets/header/header_edit.svg) center no-repeat;
  display: block;
  cursor: pointer;
}

.attachment {
  display: block;
  width: 30px;
  height: 30px;
  background: url(../../assets/header/attachment_white.svg) center no-repeat;
  position: relative;
}
